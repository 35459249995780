import React from 'react';
import '../App.css';
import { Box, useToast } from '@chakra-ui/core';
import { Route, Switch, useRouteMatch, useHistory, useLocation, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Email from './Email';
import Password from './Password';
import { showErrorToast, showSuccessToast } from '../utils';

const ForgotPassword = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const toast = useToast();

  const onNext = async (data = {}) => {
    const { email, password, code } = data;

    switch (pathname) {
      case '/forgot/email':
        {
          try {
            await Auth.forgotPassword(email);
            history.push(`/forgot/password?email=${encodeURIComponent(email)}`);
          } catch (err) {
            showErrorToast(toast, err.message);
          }
        }
        break;
      case '/forgot/password':
        {
          try {
            const urlParams = new URLSearchParams(window.location.search);
            const resetEmail = decodeURIComponent(urlParams.get('email'));
            await Auth.forgotPasswordSubmit(resetEmail, code, password);

            showSuccessToast(toast, 'Password reset. Please log in with your new password.');
            history.push('/login');
          } catch (err) {
            showErrorToast(toast, err.message);
          }
        }
        break;
    }
  };

  return (
    <Box p={[3, 3, 10]} mx="auto" my={5} borderWidth="1px" maxW="md">
      <Switch>
        <Route
          path={`${match.path}/email`}
          render={props => <Email onNext={onNext} {...props} />}
        />
        <Route
          path={`${match.path}/password`}
          render={props => <Password onNext={onNext} {...props} />}
        />
        <Redirect to={`${match.path}/email`} />
      </Switch>
    </Box>
  );
};

export default ForgotPassword;
