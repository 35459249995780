/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/core';
import CanvasDraw from 'react-canvas-draw';
import { debounce } from 'lodash';

const DrawingCanvas = props => {
  // Local Properties
  const { width, height, roomRef, canvasRef, canvasColor, canvasData, setCanvasData } = props;

  // State
  const [mouseDown, setMouseDown] = useState(null);
  const [canvasSize, setCanvasSize] = useState({});

  // Actions
  const getScaledScreenSize = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    if (windowWidth / windowHeight >= 16 / 9) {
      setCanvasSize({ width: (windowHeight / 9) * 16, height: windowHeight });
    } else {
      setCanvasSize({ width: windowWidth, height: (windowWidth / 16) * 9 });
    }
  };

  const sendCanvasData = savedCanvasData => {
    if (roomRef && roomRef.current) {
      if (roomRef.current.localParticipant) {
        const [localDataTrackPublication] = [
          ...roomRef.current.localParticipant.dataTracks.values(),
        ];

        if (localDataTrackPublication) {
          localDataTrackPublication.track.send(
            JSON.stringify({
              action: 'draw',
              drawMetadata: {
                savedCanvasData,
              },
            }),
          );
        }
      }
    }
  };

  const onCanvasChange = canvas => {
    // If it's not the initial render of this component, save and send canvasData
    if (!canvas.isDrawing && mouseDown !== null) {
      const savedData = canvasRef.current.getSaveData();
      sendCanvasData(savedData);
      setCanvasData(savedData);
    }
  };

  // UseEffects
  useEffect(() => {
    if (canvasRef && canvasRef.current) {
      getScaledScreenSize();
      canvasRef.current.canvasContainer.addEventListener('mousedown', () => setMouseDown(true));
      canvasRef.current.canvasContainer.addEventListener('mouseup', () => setMouseDown(false));
    }

    window.addEventListener('resize', debounce(getScaledScreenSize, 200));
  }, []);

  useEffect(() => {
    if (
      canvasRef &&
      canvasRef.current &&
      canvasSize.height &&
      canvasSize.width &&
      canvasData !== null
    ) {
      // If canvasData lines has nothing in it, this means nothing was drawn
      const jsonLocalStorage = JSON.parse(canvasData);
      if (jsonLocalStorage && jsonLocalStorage.lines.length) {
        canvasRef.current.loadSaveData(canvasData);
      }
    }
  }, [canvasSize]);

  return (
    <Flex w={width} height={height} position="absolute" justifyContent="center" alignItems="center">
      <CanvasDraw
        ref={ref => (canvasRef.current = ref)}
        hideGrid
        hideInterface
        onChange={onCanvasChange}
        brushColor={canvasColor}
        brushRadius={3}
        canvasHeight={canvasSize.height}
        canvasWidth={canvasSize.width}
        style={{ background: 'transparent' }}
      />
    </Flex>
  );
};

export default DrawingCanvas;
