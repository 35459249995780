import React from 'react';
import '../App.css';
import { Box, Flex, useToast } from '@chakra-ui/core';
import { Route, Switch, useRouteMatch, useHistory, useLocation, Redirect } from 'react-router-dom';
import CreateProfile from './CreateProfile';
import actions from '../actions';
import { showErrorToast } from '../utils';

const SignUp = ({ login, onAuthStateChange, ...rest }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const toast = useToast();

  const onNext = async (data = {}) => {
    switch (pathname) {
      case `${match.path}/profile`:
        {
          try {
            await actions.createProfile(data);
            await onAuthStateChange();
            history.push('/schedule');
          } catch (err) {
            showErrorToast(toast, err.message);
          }
        }
        break;
    }
  };

  return (
    <Box p={[3, 3, 10]} mx="auto" my={5} borderWidth="1px" maxW="md">
      <Switch>
        <Route
          path={`${match.path}/profile`}
          render={props => <CreateProfile onNext={onNext} {...props} />}
        />
        <Redirect to={`${match.path}/profile`} />
      </Switch>
    </Box>
  );
};

export default SignUp;
