/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Stack,
  useDisclosure,
  ModalBody,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
} from '@chakra-ui/core';
import { LocalDataTrack, createLocalTracks, connect } from 'twilio-video';
import actions from '../actions';
import Participant from './Participant';
import { pollAudioLevel } from './helpers/pollAudioLevel';

const VideoWall = ({
  token,
  roomId,
  onEndRoom,
  teacherName,
  loading,
  students,
  startAt = 0,
  duration = 0,
  slotId,
  questions = [],
  triggers = {},
  setRoomRef,
  drawingMode,
  currentSlide,
  currentQuestion,
  setCurrentQuestion,
  displayMode,
}) => {
  // State
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [participantStarCountMap, setParticipantStarCountMap] = useState({});
  const [currentGame, setCurrentGame] = useState(null);
  const [participantsVideosHidden, setParticipantsVideosHidden] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [countdown, setCountdown] = useState('00:00');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [teacherAudioLevel, setTeacherAudioLevel] = useState(null);

  const [participantMap, setParticipantMap] = useState(() => {
    const retval = { teacher: { name: teacherName } };
    students.forEach(
      s =>
        (retval[s.id] = { name: s.name, color: s.color, volume: displayMode === 'full' ? 0 : 1 }),
    );
    return retval;
  });

  // const dominantSpeakerChanged = participant => {
  //   console.log('The new dominant speaker in the Room is:', participant);
  // };

  // Actions
  const trackpubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null);

  const endLocalRoom = () => {
    if (room && room.localParticipant.state === 'connected') {
      room.localParticipant.tracks.forEach(trackPublication => {
        if (trackPublication.track.kind !== 'data') trackPublication.track.stop();
        trackPublication.unpublish();
      });

      room.disconnect();
      window.removeEventListener('beforeunload', () => room.disconnect());
      window.removeEventListener('pagehide', () => room.disconnect());
    }
  };

  const handleOnEndRoom = async () => {
    try {
      onClose();

      const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
      if (localDataTrackPublication) {
        localDataTrackPublication.track.send(JSON.stringify({ action: 'completed' }));
      }

      endLocalRoom();
      await onEndRoom();
    } catch (err) {
      console.err(err);
    }
  };

  const handleOnStarEvent = participantId => {
    const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
    if (localDataTrackPublication) {
      try {
        localDataTrackPublication.track.send(JSON.stringify({ action: 'star', participantId }));
        const participantStarValue = participantStarCountMap[participantId] || 0;

        setParticipantStarCountMap(prevState => {
          return { ...prevState, [participantId]: participantStarValue + 1 };
        });
        actions.createStarActionAsync(slotId, participantId);
      } catch (err) {
        console.warn(err);
      }
    }
  };

  const onVolumeChange = (participantId, value) => {
    if (!participantMap[participantId]) participantMap[participantId] = {};

    Object.keys(participantMap).forEach(p => {
      if (participantMap[p].volume === 8 && value === 8) {
        participantMap[p].volume =
          'prevVolume' in participantMap[p] ? participantMap[p].prevVolume : 1;
      }
    });
    participantMap[participantId].prevVolume = participantMap[participantId].volume;
    participantMap[participantId].volume = value;

    setParticipantMap(participantMap);
    setParticipants([...participants]);

    const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
    if (localDataTrackPublication)
      localDataTrackPublication.track.send(JSON.stringify({ participants: participantMap }));
  };

  const toggleMute = () => {
    if (!room || !room.localParticipant || !room.localParticipant.audioTracks) return;
    const iterator = room.localParticipant.audioTracks.values();
    const publication = iterator.next().value;
    if (publication) {
      if (publication.isTrackEnabled) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
      setIsMuted(publication.isTrackEnabled === false);
    }
  };

  useEffect(() => {
    setParticipantMap(prevMap => {
      if (!prevMap) return;

      for (const student of students) {
        prevMap[student.id].displayIndex = student.displayIndex;
      }

      return { ...prevMap };
    });
  }, [students]);

  const participantConnected = participant => {
    setParticipants(prevParticipants => {
      for (const student of students) {
        if (student.id === participant.identity && !('displayIndex' in student)) {
          const displayIndices = students.filter(s => 'displayIndex' in s).map(s => s.displayIndex);
          const maxDisplayIndex = displayIndices.length > 0 ? Math.max(displayIndices) + 1 : 0;
          student.displayIndex = maxDisplayIndex;
        }
      }
      return [...prevParticipants, participant];
    });
    setParticipantStarCountMap(prevParticipants => {
      return { ...prevParticipants, [participant.identity]: 0 };
    });
  };

  const participantDisconnected = participant => {
    setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
  };

  const createLocalTracksAction = async () => {
    const localTracks = await createLocalTracks({
      audio: true,
      video: {
        width: 320,
        frameRate: 30,
      },
    });

    const twilioRoom = await connect(token, {
      name: roomId,
      automaticSubscription: false,
      tracks: [new LocalDataTrack(), ...localTracks],
      // dominantSpeaker: true,
      // bandwidthProfile: {
      //   dominantSpeakerPriority: 'high',
      //   video: {
      //     renderDimensions: {
      //       high: { width: 640, height: 480 },
      //       standard: { width: 320, height: 480 },
      //       low: { width: 176, height: 144 },
      //     },
      //   },
      // },
    });

    setRoom(twilioRoom);

    if (setRoomRef) {
      // We set this so we can user room data in Dashboard component
      setRoomRef(twilioRoom);
    }

    // After teacher publishes tracks, set priorities to high for teacher
    twilioRoom.localParticipant.tracks.forEach(track => {
      track.setPriority('high');
    });

    const localAudioTracks = trackpubsToTracks(twilioRoom.localParticipant.audioTracks);
    pollAudioLevel(localAudioTracks, setTeacherAudioLevel);

    twilioRoom.on('participantConnected', participantConnected);
    twilioRoom.on('participantDisconnected', participantDisconnected);
    // twilioRoom.on('dominantSpeakerChanged', dominantSpeakerChanged);
    twilioRoom.participants.forEach(participantConnected);
    // Listen to the "beforeunload" event on window to leave the Room
    // when the tab/browser is being closed.
    window.addEventListener('beforeunload', () => twilioRoom.disconnect());

    // iOS Safari does not emit the "beforeunload" event on window.
    // Use "pagehide" instead.
    window.addEventListener('pagehide', () => twilioRoom.disconnect());
  };

  const triggerQuestion = questionId => {
    const question = questions.find(q => q.id === questionId);
    setCurrentQuestion(question);
    if (room && room.localParticipant) {
      const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
      if (localDataTrackPublication) {
        const payload = {
          action: 'question',
          question,
        };
        localDataTrackPublication.track.send(JSON.stringify(payload));
      }
    }
  };

  const triggerGame = game => {
    setCurrentGame(game);
    // If there is a question before, let's null it out
    triggerQuestion(null);

    if (room && room.localParticipant) {
      const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
      if (localDataTrackPublication) {
        const payload = {
          action: 'game',
          game,
        };
        localDataTrackPublication.track.send(JSON.stringify(payload));
      }
    }
  };

  const endPoll = () => {
    setCurrentQuestion(null);
    const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
    if (localDataTrackPublication) {
      const payload = { action: 'question' };
      localDataTrackPublication.track.send(JSON.stringify(payload));
    }
  };

  const revealAnswer = () => {
    if (currentQuestion) {
      setCurrentQuestion(prevQuestion => {
        const updatedQuestion = { ...prevQuestion, showAnswer: true };
        const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
        if (localDataTrackPublication) {
          const payload = { action: 'question', question: updatedQuestion };
          localDataTrackPublication.track.send(JSON.stringify(payload));
        }

        const studentAnswers = Object.keys(updatedQuestion.studentAnswers || {});
        const correctAnswer = updatedQuestion.answers.find(a => a.isCorrect);

        if (correctAnswer) {
          studentAnswers.forEach(studentId => {
            if (updatedQuestion.studentAnswers[studentId] === correctAnswer.title) {
              handleOnStarEvent(studentId);
            }
          });
        }
        return updatedQuestion;
      });
    }
  };

  // UseEffects
  useEffect(() => {
    const interval = setInterval(() => {
      const durationMs = duration * 60 * 1000;
      const hasHours = duration / 60 >= 1.0;
      const endAt = startAt + durationMs;
      if (Date.now() < startAt) {
        const hours = Math.floor(duration / 60);
        setCountdown(hasHours ? `${hours}:${duration}:00` : `${duration}:00`);
      } else if (Date.now() > startAt && Date.now() < endAt) {
        const elapsed = Date.now() - startAt;
        const remainingMs = durationMs - elapsed;

        const hours = remainingMs / 1000 / 60 / 60;
        const rhours = Math.floor(hours).toString().padStart(2, '0');
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.floor(minutes).toString().padStart(2, '0');
        const seconds = (minutes - rminutes) * 60;
        const reconds = Math.floor(seconds).toString().padStart(2, '0');

        setCountdown(hasHours ? `${rhours}:${rminutes}:${reconds}` : `${rminutes}:${reconds}`);
      } else {
        setCountdown(hasHours ? '00:00:00' : '00:00');
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    try {
      createLocalTracksAction();
    } catch (err) {
      console.warn('LocalTrack Creation Failed: ', err);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (room && room.localParticipant) {
        const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
        if (localDataTrackPublication) {
          localDataTrackPublication.track.send(
            JSON.stringify({
              action: 'heartbeat',
              participants: participantMap,
              participantsVideosHidden,
              currentSlide,
            }),
          );
        }
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [room, participantsVideosHidden, currentSlide]);

  useEffect(() => {
    const trigger = triggers[currentSlide.toString()];

    if (trigger && trigger.poll) {
      // There could be a scenario where the slide has a question before a game
      // Since we overlay game iframe on top of current slide
      // The poll would show on previous action when going back to a game slide
      // Safe way to handle this is always null out game trigger if we ever hit poll slide
      triggerGame(null);
      triggerQuestion(trigger.poll);
    } else if (trigger && trigger.game) {
      triggerGame(trigger.game);
    } else {
      triggerQuestion(null);
      triggerGame(null);
    }

    if (room && room.localParticipant) {
      const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
      if (localDataTrackPublication) {
        localDataTrackPublication.track.send(
          JSON.stringify({
            action: 'slide',
            slide: currentSlide,
          }),
        );
      }
    }
  }, [currentSlide]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (room && room.localParticipant) {
        const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
        if (localDataTrackPublication) {
          localDataTrackPublication.track.send(
            JSON.stringify({
              action: 'game',
              game: currentGame,
            }),
          );
        }
      }
    }, 2000);

    if (!currentGame) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [currentGame]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (room && room.localParticipant) {
        const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
        if (localDataTrackPublication) {
          localDataTrackPublication.track.send(
            JSON.stringify({
              action: 'question',
              question: currentQuestion,
            }),
          );
        }
      }
    }, 2000);

    if (!currentQuestion) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [currentQuestion]);

  useEffect(() => {
    if (currentQuestion && currentQuestion.showAnswer && room) {
      const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
      if (localDataTrackPublication) {
        const payload = { action: 'question', question: currentQuestion };
        localDataTrackPublication.track.send(JSON.stringify(payload));
      }

      const studentAnswers = Object.keys(currentQuestion.studentAnswers || {});
      const correctAnswer = currentQuestion.answers.find(a => a.isCorrect);

      if (correctAnswer) {
        studentAnswers.forEach(studentId => {
          if (currentQuestion.studentAnswers[studentId] === correctAnswer.title) {
            handleOnStarEvent(studentId);
          }
        });
      }
    }
  }, [room, currentQuestion]);

  // Render Methods
  const renderEndRoomPopover = () => (
    <Popover placement="top-start" isOpen={isOpen} usePortal>
      <PopoverTrigger>
        <Button isLoading={loading} bg="red.500" fontWeight="medium" color="white" onClick={onOpen}>
          End Room
        </Button>
      </PopoverTrigger>
      <PopoverContent
        minW={20}
        border="1px solid whitesmoke"
        borderRadius={3}
        bg="rgba(0, 0, 0, 0.8)"
        p={5}
        zIndex={20}
      >
        <PopoverArrow />
        <PopoverHeader color="white" fontWeight="bold" textAlign="center">
          Is Class Over?
        </PopoverHeader>
        <PopoverBody color="whitesmoke">
          <Text color="whitesmoke">Are you sure you want to end the room?</Text>
          <Flex flexDir="row" justifyContent="center">
            <Button
              color="white"
              bg="gray.500"
              _hover={{ bg: 'gray.400' }}
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button color="white" bg="red.500" _hover={{ bg: 'red.400' }} onClick={handleOnEndRoom}>
              End
            </Button>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );

  const onDataTrackMessage = (eventString = '') => {
    let eventData;
    try {
      eventData = JSON.parse(eventString);
    } catch (err) {
      console.warn(err);
      return;
    }

    const { action, data = {} } = eventData;
    if (action === 'answer') {
      setCurrentGame(prevGame => {
        if (prevGame) {
          const studentAnswers = prevGame.studentAnswers || {};
          studentAnswers[data.student_id] = `✅ Responded`;
          console.log('Student Answers: ', studentAnswers);
          return { ...prevGame, studentAnswers };
        }

        return prevGame;
      });

      setCurrentQuestion(prevQuestion => {
        if (prevQuestion) {
          const studentAnswers = prevQuestion.studentAnswers || {};
          studentAnswers[data.student_id] = data.answer_id;

          return { ...prevQuestion, studentAnswers };
        }

        return prevQuestion;
      });
    }
  };

  const renderRemoteParticipants = () => {
    students.sort((a, b) => {
      const aDisplayIndex = 'displayIndex' in a ? a.displayIndex : Number.MAX_SAFE_INTEGER;
      const bDisplayIndex = 'displayIndex' in b ? b.displayIndex : Number.MAX_SAFE_INTEGER;

      return aDisplayIndex - bDisplayIndex;
    });

    const remoteParticipants = students.map(s => {
      let currentParticipantVolume = 1;
      if (participantMap[s.id] && 'volume' in participantMap[s.id]) {
        currentParticipantVolume = participantMap[s.id].volume;
      }

      const participant = participants.filter(p => p.identity === s.id).pop();

      let studentResponse;
      if (currentGame || currentQuestion) {
        const { studentAnswers = {} } = currentGame || currentQuestion;
        studentResponse = studentAnswers[s.id];
      }

      return (
        <Box key={s.id} w={displayMode === 'full' ? '25%' : 1 / 3} pr={1} pb={1}>
          <Participant
            participant={participant}
            student={s}
            volume={currentParticipantVolume}
            onVolumeChange={onVolumeChange}
            onStarEvent={handleOnStarEvent}
            setParticipantsVideosHidden={setParticipantsVideosHidden}
            didReceiveDataTrackMessage={onDataTrackMessage}
            videoHidden={participantsVideosHidden.includes(s.id)}
            participantStarCount={participantStarCountMap[s.id]}
            studentResponse={studentResponse}
            currentGame={currentGame}
            currentQuestion={currentQuestion}
          />
        </Box>
      );
    });

    return remoteParticipants;
  };

  const renderQuestionModal = () => (
    <Box
      d="flex"
      alignItems="center"
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '30%',
        height: '100%',
        zIndex: 1400,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      p={5}
      marginTop="auto"
    >
      <Box
        bg="white"
        borderRadius="4px"
        borderWidth="2px"
        borderColor="black"
        boxShadow="2px 2px 3px #444444"
        w="100%"
      >
        <ModalBody mt={10}>
          <Text fontSize="md" color="gray.500" textAlign="center">
            🔴 LIVE ON STUDENT SCREEN 🔴
          </Text>
          <Text mt={8} fontWeight="bold" fontSize="2xl" textAlign="center">
            {currentQuestion.title}
          </Text>
          <Stack mt={4}>
            {currentQuestion.answers.map(a => (
              <Box
                key={a.title}
                p={2}
                borderWidth={a.isCorrect ? '3px' : '1px'}
                borderColor={a.isCorrect ? 'purple.500' : 'black'}
                rounded="full"
              >
                <Text fontSize="xl" fontWeight={a.isCorrect ? 'bold' : 'normal'} textAlign="center">
                  {a.title}
                </Text>
              </Box>
            ))}
          </Stack>
          <Divider />
          <Text fontWeight="medium" fontSize="lg" mt={3}>
            Student Answers
          </Text>
          <Stack mt={3}>{renderStudentAnswers(currentQuestion.studentAnswers)}</Stack>
          <Divider />

          <Box mt={3} d="flex" justifyContent="space-between">
            <Button
              isDisabled={currentQuestion && currentQuestion.showAnswer}
              h="50px"
              onClick={revealAnswer}
              bg="purple.500"
              fontWeight="medium"
              color="white"
            >
              {currentQuestion && currentQuestion.showAnswer ? 'Answer Revealed' : 'Reveal Answer'}
            </Button>
            <Button h="50px" onClick={endPoll} bg="red.500" fontWeight="medium" color="white">
              End Question
            </Button>
          </Box>
        </ModalBody>
      </Box>
    </Box>
  );

  const renderStudentAnswers = (answers = {}) => {
    const studentsCopy = students.slice();
    studentsCopy.sort((a, b) => {
      return a.name - b.name;
    });

    return studentsCopy.map(s => (
      <Box d="flex" key={s.id} w="100%">
        <Text fontWeight="bold">{`${s.name} -`}</Text>
        <Text ml={1} color={answers[s.id] ? 'black' : 'gray.500'}>
          {answers[s.id] || 'waiting on answer...'}
        </Text>
      </Box>
    ));
  };

  const renderTeacherAudioIndicator = () => {
    let renderedAudioLevel = '';
    if (teacherAudioLevel >= 8) renderedAudioLevel = `)))`;
    else if (teacherAudioLevel >= 6) renderedAudioLevel = '))';
    else if (teacherAudioLevel >= 3) renderedAudioLevel = ')';
    // else if (teacherAudioLevel === 0) renderedAudioLevel = '⛔️';

    return (
      <Box bg="purple.200" borderRadius="4px" p={1} w="45px">
        <Text color="black">{`🔈${renderedAudioLevel}`}</Text>
      </Box>
    );
  };

  return (
    <Box
      d={drawingMode ? 'none' : 'block'}
      bg="black"
      p={0}
      borderWidth="3px"
      borderColor="purple.400"
      w="100%"
    >
      {room && (
        <>
          {/* Teacher Controls */}
          <Box
            d="flex"
            alignItems="center"
            justifyContent="space-between"
            overflow="hidden"
            borderBottomWidth="3px"
            borderBottomColor="purple.400"
            p={3}
          >
            {/* Controls */}
            <Box h="100%" w="25%" textAlign="center">
              <Box d="flex" w="100%" justifyContent="space-around" flexDir="column">
                <Text color="white" fontWeight="medium">
                  Room Controls
                </Text>
                {renderEndRoomPopover()}
                <Button
                  isLoading={loading}
                  bg="blue.500"
                  color="white"
                  fontWeight="medium"
                  onClick={toggleMute}
                  mt={2}
                >
                  {isMuted ? 'Unmute Myself' : 'Mute Myself'}
                </Button>
              </Box>
            </Box>

            {/* Timer And Questions */}
            <Box maxH="100%" w="50%" textAlign="center">
              <Box d="flex" w="100%" justifyContent="center" alignItems="center" flexDir="column">
                <Text color="white" fontWeight="medium">
                  Time Remaining
                </Text>
                <Text color="white" fontSize="5xl" fontWeight="bold">
                  {countdown}
                </Text>
              </Box>
            </Box>

            <Box w="25%" position="relative" alignItems="center">
              <Flex position="absolute" w="100%" p={3}>
                {renderTeacherAudioIndicator()}
              </Flex>
              <Participant key={room.localParticipant.sid} participant={room.localParticipant} />
            </Box>
          </Box>

          {/* Students */}
          <Box d="flex" flexWrap="wrap" bg="black" w="100%" pl="0.25rem" pt="0.25rem">
            {renderRemoteParticipants()}
          </Box>
        </>
      )}

      {/* currentQuestion && currentQuestion !== null && renderQuestionModal() */}
    </Box>
  );
};

export default VideoWall;
