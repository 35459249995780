import React from 'react';
import { useForm } from 'react-hook-form';
import '../App.css';
import { FormErrorMessage, FormControl, Input, Button, Text, Flex, Box } from '@chakra-ui/core';
import { Link } from 'react-router-dom';

const CreateProfile = ({ onNext, mode, ...rest }) => {
  const { handleSubmit, errors, register, formState } = useForm();
  const validateEmail = value => {
    let error;
    if (!value) {
      error = 'Email is required';
    }
    return error || true;
  };

  const validatePassword = value => {
    let error;
    if (!value) {
      error = 'Password is required';
    } else if (value.length < 8) {
      error = 'Password must be at least 8 characters';
    }
    return error || true;
  };

  const validateName = value => {
    let error;
    if (!value) {
      error = 'Name is required';
    }
    return error || true;
  };

  return (
    <Box pb={5} w="100%" {...rest} textAlign="center">
      <Text>Please create your teacher profile below 🙂</Text>
      <form onSubmit={handleSubmit(onNext)}>
        <FormControl mt={5} isInvalid={errors.name}>
          <Text fontWeight="bold">First Name</Text>
          <Input
            name="first_name"
            placeholder="First Name"
            autoFocus
            borderRadius="4px"
            height="70px"
            textAlign="center"
            fontSize="lg"
            ref={register({ validate: validateName })}
          />
          <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
        </FormControl>
        <FormControl mt={10} isInvalid={errors.name}>
          <Text fontWeight="bold">Last Name</Text>
          <Input
            name="last_name"
            placeholder="Last Name"
            borderRadius="4px"
            height="70px"
            textAlign="center"
            fontSize="lg"
            ref={register({ validate: validateName })}
          />
          <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
        </FormControl>
        <FormControl mt={10} isInvalid={errors.email}>
          <Text fontWeight="bold">Email</Text>
          <Input
            name="email"
            placeholder="Email"
            borderRadius="4px"
            height="70px"
            textAlign="center"
            fontSize="lg"
            type="email"
            ref={register({ validate: validateEmail })}
          />
          <Text mt={1} fontSize="xs" color="gray.500">
            You will use this to sign in
          </Text>
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>
        <FormControl mt={10} isInvalid={errors.password}>
          <Text fontWeight="bold">Password</Text>
          <Input
            name="password"
            placeholder="Password"
            type="password"
            borderRadius="4px"
            height="70px"
            textAlign="center"
            fontSize="lg"
            ref={register({ validate: validatePassword })}
          />
          <FormErrorMessage textAlign="center">
            {errors.password && errors.password.message}
          </FormErrorMessage>
          <Text mt={1} fontSize="xs" color="gray.500">
            At least 8 characters
          </Text>
        </FormControl>
        <Button
          mt={10}
          variantColor="purple"
          isLoading={formState.isSubmitting}
          type="submit"
          h="50px"
          borderRadius="4px"
          w="100%"
          fontSize="lg"
          fontWeight="bold"
          style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
          onClick={handleSubmit(onNext)}
        >
          Create Profile
        </Button>
      </form>

      <Flex justify="center" align="center" flexDir="column">
        <Box h="1px" w="75%" m={5} backgroundColor="gray.200" />
        <Text fontSize="md" fontWeight="medium">
          Already have a teacher profile?{' '}
          <Link style={{ textDecoration: 'underline' }} to="/login">
            Sign In
          </Link>
        </Text>
      </Flex>
    </Box>
  );
};

export default CreateProfile;
