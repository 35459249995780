/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/core';
import * as typeformEmbed from '@typeform/embed';

const ReviewScreen = () => {
  // References
  const typeformRef = useRef(null);

  // UseEffects
  useEffect(() => {
    typeformEmbed.makeWidget(
      typeformRef.current,
      encodeURI(`https://zipschool.typeform.com/to/v7ssBtQJ`),
      {
        hideFooter: true,
        hideHeaders: true,
        opacity: 0,
        transferableUrlParameters: ['teachername', 'classtitle'],
      },
    );
  }, [typeformRef]);

  return <Box ref={typeformRef} style={{ height: '100vh', width: '100%' }} />;
};

export default ReviewScreen;
