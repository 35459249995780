import React from 'react';
import { useForm } from 'react-hook-form';
import '../App.css';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Text,
  Box,
} from '@chakra-ui/core';

const Email = ({ onNext, ...rest }) => {
  const { handleSubmit, errors, register, formState } = useForm();

  const validateEmail = value => {
    let error;
    if (!value) {
      error = 'Email is required';
    }
    return error || true;
  };

  return (
    <Box m={0} pt={5} pb={5} {...rest} textAlign="center">
      <Text fontSize="lg">Please enter your email below 🙂</Text>
      <form onSubmit={handleSubmit(onNext)}>
        <FormControl mt={10} isInvalid={errors.email}>
          <Input
            name="email"
            placeholder="Email"
            autoFocus
            borderRadius="4px"
            height="70px"
            textAlign="center"
            fontSize="lg"
            type="email"
            ref={register({ validate: validateEmail })}
          />
          <Text mt={1} fontSize="xs" color="gray.500">
            Enter the email you used to create your profile
          </Text>
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>
        <Button
          mt={10}
          variantColor="purple"
          isLoading={formState.isSubmitting}
          type="submit"
          h="50px"
          borderRadius="4px"
          w="100%"
          fontSize="lg"
          fontWeight="bold"
          style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
          onClick={handleSubmit(onNext)}
        >
          Next
        </Button>
      </form>
    </Box>
  );
};

export default Email;
