import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

const TIME_ZONES = {
  EST: 'Eastern',
  CST: 'Central',
  MST: 'Mountain',
  PST: 'Pacific',
  EDT: 'Eastern',
  CDT: 'Central',
  MDT: 'Mountain',
  PDT: 'Pacific',
};

const showErrorToast = (toast, message) => {
  toast({
    title: 'Woops!',
    description: message,
    status: 'error',
    duration: 5000,
    isClosable: true,
  });
};

const showSuccessToast = (toast, message) => {
  toast({
    title: 'Success!',
    description: message,
    status: 'success',
    duration: 5000,
    isClosable: true,
  });
};

Date.prototype.stdTimezoneOffset = function () {
  const jan = new Date(this.getFullYear(), 0, 1);
  const jul = new Date(this.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

Date.prototype.isDstObserved = function () {
  return this.getTimezoneOffset() < this.stdTimezoneOffset();
};

const convertTZ = (timestamp, options = {}) => {
  const { mode, showTimeZone } = options;
  const tz = dayjs.tz.guess();
  if (mode === 'countdown') {
    const today = dayjs();
    return today.to(timestamp, true);
  }

  let [date, timeZoneCode] = dayjs(timestamp).tz(tz).format('ddd, MMM D - h:mmA#z').split('#');

  if (mode === 'date') return date.split(' - ').shift();
  if (mode === 'time') date = date.split(' - ').pop();

  if (showTimeZone)
    return TIME_ZONES[timeZoneCode]
      ? `${date} ${TIME_ZONES[timeZoneCode]}`
      : `${date} ${timeZoneCode}`;

  return date;
};

export { convertTZ, showErrorToast, showSuccessToast };
