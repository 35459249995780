import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
import ReviewScreen from '../ReviewScreen';
import FeedbackScreen from '../FeedbackScreen';
import actions from '../actions';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { showErrorToast } from '../utils';
import { useToast } from '@chakra-ui/core';

const DashboardRouter = () => {
  const [zipClass, setZipClass] = useState();
  const [loading, setLoading] = useState(false);
  const { slot_id: slotId } = useParams();
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();

  // UseEffects
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { zipClass: zipClassResult } = await actions.fetchZipClassroom(slotId);
        if (zipClassResult) {
          setZipClass(zipClassResult);
        }
      } catch (err) {
        showErrorToast(toast, err.message);
        history.push('/schedule');
      }
      setLoading(false);
    };

    fetchData();
  }, [location]);

  return (
    <Switch>
      <Route
        exact
        path="/rooms/:slot_id/feedback"
        render={() => <FeedbackScreen zipClass={zipClass} />}
      />
      <Route path="/rooms/:slot_id/review" render={() => <ReviewScreen zipClass={zipClass} />} />
      <Route
        path="/rooms/:slot_id"
        render={() => <Dashboard loading={loading} zipClass={zipClass} />}
      />
    </Switch>
  );
};

export default DashboardRouter;
