/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  Spinner,
  Stack,
  Text,
  Textarea,
  Input,
  FormErrorMessage,
  Button,
  useToast,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionIcon,
  AccordionPanel,
  ListItem,
  List,
  ListIcon,
  Divider,
} from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { showErrorToast, showSuccessToast } from '../../src/utils';
import actions from '../../src/actions';
import { useParams, useHistory } from 'react-router-dom';

const ReviewScreen = ({ zipClass }) => {
  // State
  const [loading, setLoading] = useState(false);

  const form = useForm();
  const toast = useToast();
  const history = useHistory();
  const { slot_id: slotId } = useParams();

  // Actions
  const onSubmitFeedback = async data => {
    const students = Object.keys(data).map(id => {
      return {
        id,
        feedback: data[id],
      };
    });

    setLoading(true);
    try {
      await actions.sendFeedback(slotId, students);
      showSuccessToast(toast, '✅ Class Feedback Submitted!');

      const classTitle = zipClass ? zipClass.classTitle : '';
      const teacherName = zipClass ? zipClass.teacherName : '';

      history.push(
        encodeURI(`/rooms/${slotId}/review?teachername=${teacherName}&classtitle=${classTitle}`),
      );
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setLoading(false);
  };

  const renderInputFields = () => {
    if (!zipClass || !zipClass.students) return;

    const validStudents = zipClass.students.filter(s => s.did_attend);

    return (
      <Stack spacing={5}>
        {validStudents.length > 0 && validStudents.map(renderStudentInputField)}
        {validStudents.length === 0 && (
          <Box textAlign="center">
            <Text>No students attended class today :(</Text>
            <Text>Please click the "Submit" button below</Text>
          </Box>
        )}
        <Button
          isLoading={loading}
          bg="purple.500"
          fontSize="lg"
          fontWeight="bold"
          color="white"
          h="50px"
          onClick={form.handleSubmit(async data => await onSubmitFeedback(data))}
        >
          Submit
        </Button>
      </Stack>
    );
  };

  const validateInput = input => {
    return input && input.length > 0 ? true : 'Feedback required';
  };

  const renderStudentInputField = student => (
    <FormControl key={student.id} isInvalid={form.errors[student.id]}>
      <Text fontSize={['sm', 'lg']}>{student.name}</Text>
      <Textarea
        name={student.id}
        placeholder={`Class feedback for ${student.name}`}
        h={[45, 60]}
        rounded="lg"
        fontSize={['md', 'lg']}
        ref={form.register({ validate: validateInput })}
      />
      <FormErrorMessage display="inline-flex" bg="#EDF2F7" rounded="full" p={2}>
        {form.errors[student.id] && form.errors[student.id].message}
      </FormErrorMessage>
    </FormControl>
  );

  return zipClass ? (
    <Box style={{ height: '100vh', width: '100%' }}>
      <Box mx="auto" mt={[0, null, '3em']} maxW="lg">
        <Stack spacing={4}>
          <Text textAlign="center" fontSize="xl" fontWeight="bold">
            {`${zipClass.classTitle} Feedback`}
          </Text>
          <Text>
            Welcome to ZipSchool’s feedback section! Basically how this works is, for each student
            below write a quick 2-3 sentences to their parents about how their kid did in class.
          </Text>
          <Text>
            Focus on the positives! Were there any highlights where the kid was brilliant? Were they
            super attentive throughout class and in love with the topic? Were they always following
            specific movements you were doing? Did they answer any specific questions really well?
          </Text>
          <Text>
            This is purely to highlight for parents what went really well with their kid during
            class. You want to pick a moment that really stood out.
          </Text>
          <Accordion>
            <AccordionItem>
              <AccordionHeader>
                <Box fontWeight="bold" flex="1" textAlign="left">
                  Good Examples
                </Box>
                <AccordionIcon />
              </AccordionHeader>
              <AccordionPanel pb={4}>
                <List spacing={2}>
                  <ListItem fontSize="sm">
                    <ListIcon icon="check-circle" color="green.500" />
                    Jon did great in class today! She obviously loves dolphins a lot and seemed to
                    know a lot about them already. Be sure to ask him more at home about how
                    "blowholes" work he seemed to be really curious about that!
                  </ListItem>
                  <ListItem fontSize="sm">
                    <ListIcon icon="check-circle" color="green.500" />
                    Katie was brilliant in class. She was extremely focused the entire time and
                    seemed to really love learning about how rockets blast off. She seemed to be a
                    bit quiet today and didn't want to answer many questions but participated in
                    every poll question!
                  </ListItem>
                  <ListItem fontSize="sm">
                    <ListIcon icon="check-circle" color="green.500" />
                    Zion was a pleasure to have as always. I love how he brought his own stories
                    about when he flew on an airplane when we were discussing how airplanes take
                    off. Love the energy he brings to class!
                  </ListItem>
                </List>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionHeader>
                <Box fontWeight="bold" flex="1" textAlign="left">
                  Bad Examples
                </Box>
                <AccordionIcon />
              </AccordionHeader>
              <AccordionPanel pb={4}>
                <List spacing={2}>
                  <ListItem fontSize="sm">
                    <ListIcon icon="close" color="red.500" />
                    Jon was very laggy the entire time and I was having a hard time hearing him but
                    he seemed very attentive and loved his energy.
                  </ListItem>
                  <ListItem fontSize="sm">
                    <ListIcon icon="close" color="red.500" />
                    Katie seemed to not be into the topic at all and didn't pay much attention
                    throughout class, she seemed very distracted.
                  </ListItem>
                  <ListItem fontSize="sm">
                    <ListIcon icon="close" color="red.500" />
                    Loved Zion's stories!
                  </ListItem>
                </List>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Box mt={4}>
            <Text fontWeight="bold" fontSize="xl">
              Enter Feedback
            </Text>
            <Divider />
            {renderInputFields()}
          </Box>
        </Stack>
      </Box>
    </Box>
  ) : (
    <Spinner />
  );
};

export default ReviewScreen;
