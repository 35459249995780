import React, { useEffect } from 'react';
import { Box, Heading, Flex, Button } from '@chakra-ui/core';
import { useLocation, Link } from 'react-router-dom';

const Header = ({ handleLogout, authState, onRouteChange, ...props }) => {
  const location = useLocation();

  useEffect(() => {
    if (onRouteChange) onRouteChange(location.pathname);
  }, [location]);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
      bg="purple.500"
      color="white"
      {...props}
    >
      <Flex align="center" mr={5}>
        <Heading as="h1" size="lg">
          🚀 ZipSchool
        </Heading>
        <Box ml={3} as="span" fontSize="xs">
          TEACHER
        </Box>
      </Flex>

      {(location.pathname.startsWith('/schedule') ||
        location.pathname.startsWith('/profile') ||
        location.pathname.startsWith('/room')) && (
        <Box mt={[4, 0]}>
          <Link to="/schedule">
            <Button
              ml={3}
              bg={location.pathname.startsWith('/schedule') ? 'white' : 'transparent'}
              border={location.pathname.startsWith('/schedule') ? 'none' : '1px'}
              borderColor={location.pathname.startsWith('/schedule') ? 'none' : 'white'}
              color={location.pathname.startsWith('/schedule') ? 'black' : 'white'}
              fontWeight={location.pathname.startsWith('/schedule') ? 'bold' : 'normal'}
              rounded="full"
            >
              My Schedule
            </Button>
          </Link>
          <Link to="/profile">
            <Button
              ml={3}
              bg={location.pathname.startsWith('/profile') ? 'white' : 'transparent'}
              border={location.pathname.startsWith('/profile') ? 'none' : '1px'}
              borderColor={location.pathname.startsWith('/profile') ? 'none' : 'white'}
              color={location.pathname.startsWith('/profile') ? 'black' : 'white'}
              fontWeight={location.pathname.startsWith('/profile') ? 'bold' : 'normal'}
              rounded="full"
            >
              Profile
            </Button>
          </Link>
        </Box>
      )}
    </Flex>
  );
};

export default Header;
