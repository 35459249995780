import React, { useEffect, useState } from 'react';
import './App.css';
import { Button, Text, Stack, Divider, Box, useToast } from '@chakra-ui/core';
import { Auth } from 'aws-amplify';
import { showErrorToast } from './utils';

const Profile = ({ handleSignOut }) => {
  const [profile, setProfile] = useState(null);
  const toast = useToast();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const details = await Auth.currentUserInfo();
        if (details) setProfile(details.attributes);
      } catch (err) {
        showErrorToast(toast, err.message);
      }
    };

    fetchUser();
  }, []);
  return (
    <Box maxW="md" mx="auto" my={5} p={5}>
      <Text fontWeight="bold" fontSize="2xl">
        Profile
      </Text>
      <Divider />
      {profile && (
        <Stack textAlign="center">
          <Text fontWeight="medium">
            Hey,
            {profile.name}!
          </Text>
          <Text fontSize="sm" color="gray.600">
            {profile.email}
          </Text>
          <Text fontSize="sm" mt={5}>
            Have a question? Hit us up on slack! 🙂
          </Text>
        </Stack>
      )}
      <Divider />
      <Stack mt={4} spacing={4}>
        <Button bg="gray.100" color="gray.800" onClick={handleSignOut}>
          Sign Out
        </Button>
      </Stack>
    </Box>
  );
};

export default Profile;
