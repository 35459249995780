import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ThemeProvider, theme } from '@chakra-ui/core';
import * as serviceWorker from './serviceWorker';
import App from './App';
import CSSReset from './CSSReset';

const customTheme = {
  ...theme,
  fonts: {
    body: 'Circular, sans-serif',
    heading: 'Circular, sans-serif',
    mono: 'Circular, sans-serif',
  },
  fontWeights: {
    normal: 400,
    medium: 600,
    bold: 700,
    black: 800,
  },
};

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    autoSessionTracking: true,
    dsn: 'https://ce9be9a2f69943ddb117c533aa63bd68@o462836.ingest.sentry.io/5466791',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
