import React, { useState, useEffect } from 'react';
import './App.css';
import {
  AspectRatioBox,
  Badge,
  useToast,
  Text,
  Stack,
  Box,
  Button,
  Divider,
  Image,
  Spinner,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import actions from './actions';
import { showErrorToast, convertTZ } from './utils';

const Schedule = () => {
  const [scheduleItems, setSchedule] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  // Render Methods
  const renderCardBadge = (badgeContent, fontWeight) => (
    <Badge
      variant="subtle"
      textTransform="capitalize"
      rounded="full"
      fontSize={['xs', 'sm', 'sm', 'sm']}
      fontWeight={fontWeight}
      color="#383838"
      mt={3}
      paddingX={2}
      paddingY={1}
      background="white"
      border="1px #d8d8d8 solid"
      boxShadow="sm"
    >
      {badgeContent}
    </Badge>
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { items } = await actions.fetchSchedule();
        if (items) setSchedule(items);
        setLoading(false);
      } catch (err) {
        showErrorToast(toast, err.message);
      }
    };

    fetchData();
  }, []);

  const renderScheduleItem = (item = {}) => {
    const { timestamp, class: zipClass = {}, id, age_type: ageType, slot_type: slotType } = item;
    const { title, image_url: imageUrl } = zipClass;
    const isTrial = slotType === 'trial';

    return (
      <Box key={id} bg={isTrial ? 'yellow.300' : 'none'} mb={10} p={isTrial ? 4 : 0} rounded="lg">
        {isTrial && (
          <Text pb={2} fontSize="lg" fontWeight="bold" textAlign="center">
            🚨 Trial Class 🚨
          </Text>
        )}
        <Box
          bg="white"
          rounded="lg"
          p={6}
          boxShadow="md"
          borderWidth={isTrial ? 0 : '2px'}
          borderTopWidth={isTrial ? 0 : '2px'}
          borderColor={isTrial ? 'none' : 'gray.700'}
        >
          {imageUrl && (
            <AspectRatioBox ratio={16 / 9} boxShadow="md" rounded="lg">
              <Image objectFit="cover" rounded="lg" src={imageUrl} />
            </AspectRatioBox>
          )}
          <Text color="#383838" fontSize={['lg', 'xl']} fontWeight="black" mt={3}>
            {title}
          </Text>
          {timestamp &&
            renderCardBadge(
              `${convertTZ(timestamp, {
                showTimeZone: true,
                mode: 'date',
                dayMode: 'full',
              })} @ ${convertTZ(timestamp, {
                showTimeZone: true,
                mode: 'time',
              })}`,
              'medium',
            )}
          <Stack spacing={3} isInline>
            {renderCardBadge(`Age Group: ${ageType || '4-6'}`, 'medium')}
          </Stack>
          <Box pt={4}>
            {item.lesson_plan_url && (
              <a href={item.lesson_plan_url} target="_blank" rel="noopener noreferrer">
                <Button
                  w="100%"
                  h={[45, 60]}
                  borderRadius={80}
                  mt={5}
                  boxShadow="md"
                  fontSize={['lg', 'lg', 'xl', 'xl']}
                  fontWeight="bold"
                  color="white"
                  bg="purple.400"
                  backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                        rgba(255, 255, 255, 0) 93.72%)"
                  style={{
                    backgroundBlendMode: 'multiply, normal',
                    textShadow: '0 -1px 0 rgba(0, 0, 0, .35)',
                  }}
                  _hover={{ backgroundColor: 'purple.300' }}
                >
                  View Lesson Plan
                </Button>
              </a>
            )}
            <Link to={`/rooms/${item.sk}`}>
              <Button
                w="100%"
                h={[45, 60]}
                borderRadius={80}
                mt={5}
                boxShadow="md"
                fontSize={['lg', 'lg', 'xl', 'xl']}
                fontWeight="bold"
                color="white"
                bg="green.400"
                backgroundImage="linear-gradient(98.17deg, rgba(255, 255, 255, 0.88) 43.72%, 
                                        rgba(255, 255, 255, 0) 93.72%)"
                style={{
                  backgroundBlendMode: 'multiply, normal',
                  textShadow: '0 -1px 0 rgba(0, 0, 0, .35)',
                }}
                _hover={{ backgroundColor: 'green.300' }}
              >
                Join Class
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box maxW="md" mx="auto" my={5} p={5}>
      <Text fontWeight="bold" fontSize="2xl">
        Schedule
      </Text>
      <Divider />
      {loading && (
        <Box d="flex" justifyContent="center">
          <Spinner mt={4} color="purple.500" />
        </Box>
      )}

      <Stack py={4} spacing={2}>
        {!loading && scheduleItems.length === 0 && (
          <Text py={4} textAlign="center" fontSize="sm">
            You don't have any classes booked yet!
          </Text>
        )}
        {scheduleItems.map(renderScheduleItem)}
      </Stack>
      <Divider />
    </Box>
  );
};

export default Schedule;
