import { Auth, API } from 'aws-amplify';
import awsconfig from '../aws-exports';

const createProfile = async (profile = {}) => {
  const params = {
    body: { profile },
  };

  try {
    const { result = {} } = await API.post('ZipTeacherAPI', `/profile`, params);
    const { session = {}, user_id } = result;
    const { IdToken, AccessToken, RefreshToken } = session;
    const clientId = awsconfig.Auth.userPoolWebClientId;
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${clientId}.${user_id}.accessToken`,
      AccessToken,
    );
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${clientId}.${user_id}.refreshToken`,
      RefreshToken,
    );
    localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.${user_id}.idToken`, IdToken);
    localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.${user_id}.clockDrift`, 0);
    localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`, user_id);
    localStorage.setItem('amplify-signin-with-hostedUI', false);
    await Auth.currentSession();

    return user_id;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

/**
 * Creates a new star received record for the child
 * @param {string} slotId
 * @param {string} childId
 */
const createStarActionAsync = async (slotId, childId) => {
  const params = {
    body: { action: 'star', child_id: childId },
  };

  try {
    const { result = {} } = await API.put('ZipTeacherAPI', `/rooms/${slotId}`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const endClassroom = async slot_id => {
  const params = {
    body: { status: 'completed' },
  };

  try {
    const { result = {} } = await API.put('ZipTeacherAPI', `/rooms/${slot_id}`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchSchedule = async () => {
  try {
    const { items } = await API.get('ZipTeacherAPI', '/schedule');
    return { items };
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchZipClassroom = async slot_id => {
  try {
    const { result = {} } = await API.get('ZipTeacherAPI', `/rooms/${slot_id}`);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const sendTeacherRedAlert = async (slotId, alertPayload) => {
  const params = {
    body: { alert_payload: alertPayload },
  };

  try {
    const { result = {} } = await API.put('ZipTeacherAPI', `/rooms/${slotId}`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const sendFeedback = async (slotId, students) => {
  const params = {
    body: { action: 'feedback', students },
  };

  try {
    const { result = {} } = await API.put('ZipTeacherAPI', `/rooms/${slotId}`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

export default {
  createProfile,
  createStarActionAsync,
  endClassroom,
  fetchSchedule,
  fetchZipClassroom,
  sendTeacherRedAlert,
  sendFeedback,
};
