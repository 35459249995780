import React from 'react';
import { Box, Text, Button, Input, FormErrorMessage, FormControl, Stack } from '@chakra-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

const SignIn = ({ handleSignIn }) => {
  const { handleSubmit, errors, register, formState } = useForm();
  const history = useHistory();

  const validateEmail = value => {
    let error;
    if (!value) {
      error = 'Email is required';
    }
    return error || true;
  };

  const validatePassword = value => {
    let error;
    if (!value) {
      error = 'Password is required';
    }
    return error || true;
  };

  const onSignUp = e => {
    e.preventDefault();
    history.push('/signup');
  };

  return (
    <Stack mx="auto" mt={[0, null, '3em']} maxW="md" textAlign="center">
      <Box p={[3, 3, 10]} borderWidth="1px">
        <Text fontSize="md">Please sign in to your teacher profile 👇</Text>
        <form style={{ width: '100%' }} onSubmit={handleSubmit(handleSignIn)}>
          <FormControl mt="2rem" isInvalid={errors.email}>
            <Text fontWeight="bold">Email</Text>
            <Input
              name="email"
              placeholder="Email"
              borderRadius="4px"
              h="70px"
              fontSize="lg"
              textAlign="center"
              ref={register({ validate: validateEmail })}
            />
            <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
          </FormControl>
          <FormControl mt={5} isInvalid={errors.password}>
            <Text fontWeight="bold">Password</Text>
            <Input
              name="password"
              placeholder="Password"
              type="password"
              textAlign="center"
              borderRadius="4px"
              h="70px"
              ref={register({ validate: validatePassword })}
            />
            <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
          </FormControl>
          <Text mt={2} fontSize="xs" textAlign="right">
            Forgot your password?{' '}
            <Link style={{ textDecoration: 'underline' }} to="/forgot">
              Reset
            </Link>
          </Text>
          <Button
            mt={4}
            backgroundColor="green.500"
            color="white"
            isLoading={formState.isSubmitting}
            type="submit"
            onClick={handleSubmit(handleSignIn)}
            w="100%"
            fontSize="lg"
            fontWeight="bold"
            borderRadius="4px"
            h="50px"
            style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
          >
            Log In
          </Button>
        </form>

        <Box h="1px" w="75%" m={5} backgroundColor="gray.200" />
        <Text fontSize="sm">Don't have a teacher profile?</Text>
        <Button
          backgroundColor="purple.500"
          color="white"
          mt={2}
          isLoading={formState.isSubmitting}
          type="submit"
          onClick={onSignUp}
          w="100%"
          borderRadius="4px"
          h="50px"
          fontSize="lg"
          fontWeight="bold"
          style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
        >
          Create Teacher Profile
        </Button>
      </Box>
    </Stack>
  );
};

export default SignIn;
