import React from 'react';
import { useForm } from 'react-hook-form';
import '../App.css';
import { FormErrorMessage, FormControl, Input, Button, Text, Box } from '@chakra-ui/core';

const Password = ({ onNext, ...rest }) => {
  const { handleSubmit, errors, register, formState } = useForm();

  const validatePassword = value => {
    let error;
    if (!value) {
      error = 'Password is required';
    }
    return error || true;
  };

  const validateCode = value => {
    let error;
    if (!value) {
      error = 'Code is required';
    }
    return error || true;
  };

  return (
    <Box m={0} pt={5} pb={5} {...rest} textAlign="center">
      <Text fontSize="lg" fontWeight="bold">
        Please create a new password below 🙂
      </Text>
      <Text mt={2} fontSize="sm">
        A verification was just sent to your email. Please enter it below, then create a new
        password.
      </Text>
      <form onSubmit={handleSubmit(onNext)}>
        <FormControl mt={5} isInvalid={errors.code}>
          <Input
            name="code"
            placeholder="Email Verification Code"
            inputmode="numeric"
            pattern="[0-9]*"
            autoFocus
            borderRadius="4px"
            height="70px"
            textAlign="center"
            fontSize="lg"
            ref={register({ validate: validateCode })}
          />
          <FormErrorMessage>{errors.code && errors.code.message}</FormErrorMessage>
        </FormControl>
        <FormControl mt={5} isInvalid={errors.password}>
          <Input
            mt={3}
            name="password"
            placeholder="New Password"
            type="password"
            borderRadius="4px"
            height="70px"
            textAlign="center"
            fontSize="lg"
            ref={register({ validate: validatePassword })}
          />
          <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
        </FormControl>
        <Button
          mt={10}
          h="50px"
          borderRadius="4px"
          w="100%"
          fontSize="lg"
          fontWeight="bold"
          variantColor="purple"
          isLoading={formState.isSubmitting}
          type="submit"
          style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
          onClick={handleSubmit(onNext)}
        >
          Next
        </Button>
      </form>
    </Box>
  );
};

export default Password;
