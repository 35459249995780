/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import './App.css';
import { useToast, Text, Box, Stack } from '@chakra-ui/core';
import Amplify, { Auth } from 'aws-amplify';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import awsconfig from './aws-exports';
import Header from './Header';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Schedule from './Schedule';
import Profile from './Profile';
import ForgotPassword from './ForgotPassword';
import { showErrorToast } from './utils';
import Room from './Room';

Amplify.configure(awsconfig);

const AUTH_STATES = {
  LOADING: 0,
  NEEDS_AUTH: 1,
  AUTHENTICATED: 2,
};

const App = () => {
  const [authState, setAuthState] = useState(AUTH_STATES.LOADING);
  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const toast = useToast();

  useEffect(() => {
    const fetchAuthStateWrapper = async () => {
      await fetchAuthState();
    };

    fetchAuthStateWrapper();
  }, []);

  const fetchAuthState = async () => {
    try {
      const res = await Auth.currentAuthenticatedUser();
      if (res) setAuthState(AUTH_STATES.AUTHENTICATED);
      else setAuthState(AUTH_STATES.NEEDS_AUTH);
    } catch (err) {
      setAuthState(AUTH_STATES.NEEDS_AUTH);
    }
  };

  const onSignIn = async ({ email, password }) => {
    try {
      await Auth.signIn(email, password);
      await fetchAuthState();
    } catch (err) {
      showErrorToast(toast, err.message);
    }
  };

  const onSignOut = async () => {
    await Auth.signOut();
    await fetchAuthState();
  };

  const handleRouteChange = pathname => {
    setShowHeader(!pathname.startsWith('/rooms/'));
    setShowFooter(!pathname.startsWith('/rooms/'));
  };

  return (
    <div className="App">
      <Router>
        {showHeader && (
          <Header
            authState={authState}
            onRouteChange={handleRouteChange}
            handleLogout={onSignOut}
          />
        )}
        {authState === AUTH_STATES.LOADING && <Text>Loading...</Text>}
        {authState === AUTH_STATES.AUTHENTICATED && (
          <Switch>
            <Route exact path="/schedule" render={props => <Schedule {...props} />} />
            <Route
              exact
              path="/profile"
              render={props => <Profile handleSignOut={onSignOut} {...props} />}
            />
            <Route path="/rooms/:slot_id" render={props => <Room {...props} />} />
            <Redirect exact to="/schedule" />
          </Switch>
        )}
        {authState === AUTH_STATES.NEEDS_AUTH && (
          <Switch>
            <Route
              exact
              path="/login"
              render={props => <SignIn handleSignIn={onSignIn} {...props} />}
            />
            <Route
              path="/signup"
              render={props => <SignUp onAuthStateChange={fetchAuthState} {...props} />}
            />
            <Route
              path="/forgot"
              render={props => <ForgotPassword onAuthStateChange={fetchAuthState} {...props} />}
            />
            <Redirect exact to="/login" />
          </Switch>
        )}
      </Router>

      {showFooter && (
        <Box
          left="0"
          bottom="0"
          justify="center"
          w="100%"
          h={['70px', null, '100px']}
          p={[3, null, 5]}
          bg="white"
        >
          <Stack textAlign="center">
            <Stack spacing={5} isInline justify="center">
              <Text fontWeight="medium" fontSize="sm">
                <a
                  href="https://zipschool.webflow.io/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms Of Service
                </a>
              </Text>
              <Text fontWeight="medium" fontSize="sm">
                <a
                  href="https://zipschool.webflow.io/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </Text>
            </Stack>
            <Stack isInline justifyContent="center" alignItems="center">
              <Text color="gray.600" fontWeight="normal" fontSize="sm">
                © 2020{' '}
                <a href="https://zipschool.com?ref=app" rel="noopener noreferrer" target="_blank">
                  Zip Education Inc.
                </a>
              </Text>
              <Text color="gray.400" fontSize="xs">
                v{process.env.REACT_APP_VERSION}
              </Text>
            </Stack>
          </Stack>
        </Box>
      )}
    </div>
  );
};

export default App;
