/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { debounce, delay } from 'lodash';
import {
  Box,
  Flex,
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Stack,
  useDisclosure,
} from '@chakra-ui/core';
import { pollAudioLevel } from './helpers/pollAudioLevel';

const Participant = ({
  participant,
  student,
  volume = 1,
  onVolumeChange,
  onStarEvent,
  setParticipantsVideosHidden,
  didReceiveDataTrackMessage,
  videoHidden,
  participantStarCount,
  studentResponse,
  currentGame,
  currentQuestion,
}) => {
  // State
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [dataTracks, setDataTracks] = useState([]);
  const [showStarWarning, setShowStarWarning] = useState(false);

  const [starEventTriggered, setStartEventTriggered] = useState(false);
  const [isParticipantSpeaking, setIsParticipantSpeaking] = useState({
    isSpeaking: false,
    level: 0,
  });
  const { onOpen, onClose, isOpen } = useDisclosure();

  // References
  const videoRef = useRef();
  const audioRef = useRef();

  // Properties
  const SPEAKING_COLOR = '#39ff14';

  // Actions
  const pollStudentAudioResults = debounce(level => {
    setIsParticipantSpeaking({
      isSpeaking: level >= 7,
      level,
    });
  }, 300);

  const trackpubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null);

  const toggleHideVideo = () => {
    if (videoHidden) {
      onVolumeChange(student.id, 1);
      setParticipantsVideosHidden(prevState => {
        return prevState.filter(id => id !== student.id);
      });
    } else {
      onVolumeChange(student.id, 0);
      setParticipantsVideosHidden(prevState => {
        return [...prevState, student.id];
      });
    }

    onClose();
  };

  const triggerStarEvent = () => {
    setStartEventTriggered(true);
    onStarEvent(student.id);
    delay(() => setStartEventTriggered(false), 3000);
  };

  // UseEffects
  useEffect(() => {
    if (participant) {
      setVideoTracks(trackpubsToTracks(participant.videoTracks));
      setAudioTracks(trackpubsToTracks(participant.audioTracks));
      setDataTracks(trackpubsToTracks(participant.dataTracks));

      const trackSubscribed = track => {
        if (track.kind === 'video') {
          setVideoTracks(stateVideoTracks => [...stateVideoTracks, track]);
        } else if (track.kind === 'audio') {
          setAudioTracks(stateAudioTracks => [...stateAudioTracks, track]);
        } else if (track.kind === 'data') {
          setDataTracks(stateDataTracks => [...stateDataTracks, track]);
        }
      };

      const trackUnsubscribed = track => {
        if (track.kind === 'video') {
          setVideoTracks(stateVideoTracks => stateVideoTracks.filter(v => v !== track));
        } else if (track.kind === 'audio') {
          setAudioTracks(stateAudioTracks => stateAudioTracks.filter(a => a !== track));
        } else if (track.kind === 'data') {
          setDataTracks(stateDataTracks => stateDataTracks.filter(a => a !== track));
        }
      };

      participant.on('trackSubscribed', trackSubscribed);
      participant.on('trackUnsubscribed', trackUnsubscribed);
    }

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      setDataTracks([]);
      if (participant) {
        participant.removeAllListeners();
      }
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
    }

    return () => {
      if (videoTrack) {
        videoTrack.detach();
      }
    };
  }, [videoTracks]);

  useEffect(() => {
    let interval;
    if (participant) {
      interval = setTimeout(() => {
        setShowStarWarning(true);
      }, 1000 * 60 * 5);
    }

    return () => {
      if (interval) {
        clearTimeout(interval);
        setShowStarWarning(false);
      }
    };
  }, [starEventTriggered, participant]);

  useEffect(() => {
    const dataTrack = dataTracks[0];
    if (dataTrack) {
      dataTrack.on('message', didReceiveDataTrackMessage);
    }

    return () => {
      if (dataTrack) {
        dataTrack.off('message', didReceiveDataTrackMessage);
      }
    };
  }, [dataTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);

      // If student object here is undefined, then we know this is a teacher
      if (student) {
        pollAudioLevel(audioTracks, pollStudentAudioResults);
      }
    }

    return () => {
      if (audioTrack) {
        audioTrack.detach();
      }
    };
  }, [audioTracks]);

  useEffect(() => {
    if (audioRef) audioRef.current.volume = volume / 10;
  }, [volume]);

  const renderStarWarning = () => {
    return showStarWarning ? (
      <Box px={4} bg="rgba(0,0,0,0.1)">
        <Text color="black" fontWeight="medium" textAlign="center">
          ⭐️ Star Reminder ⭐️
        </Text>
      </Box>
    ) : (
      <Box />
    );
  };

  // Render Methods
  const renderHideVideoPopover = () => {
    const popoverBodyText = videoHidden
      ? `Enable video and audio for ${student.name}?`
      : `Disable video and audio for ${student.name}?`;
    return (
      <Popover isOpen={isOpen} usePortal>
        <PopoverTrigger>
          <Button
            px={1}
            py={1}
            bg="gray.300"
            borderRadius="2px"
            _hover={{ bg: 'gray.200' }}
            onClick={onOpen}
          >
            {videoHidden ? '🔴' : '❌'}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          minW={20}
          border="1px solid whitesmoke"
          borderRadius={3}
          bg="rgba(0, 0, 0, 0.8)"
          p={5}
          zIndex={20}
        >
          <PopoverArrow />
          <PopoverHeader color="white" fontWeight="bold">
            Are You Sure?
          </PopoverHeader>
          <PopoverBody>
            <Text color="whitesmoke">{popoverBodyText}</Text>
            <Flex mt={3} flexDir="row" justifyContent="center">
              <Button
                color="white"
                bg="gray.500"
                _hover={{ bg: 'gray.400' }}
                mr={3}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                color="white"
                bg={videoHidden ? 'green.500' : 'red.400'}
                _hover={{ bg: videoHidden ? 'green.400' : 'red.500' }}
                onClick={toggleHideVideo}
              >
                {videoHidden ? 'Enable' : 'Disable'}
              </Button>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <Stack
      borderWidth={isParticipantSpeaking.isSpeaking || student ? '5px' : '0px'}
      borderColor={isParticipantSpeaking.isSpeaking && volume !== 0 ? SPEAKING_COLOR : 'purple.400'}
      spacing="0"
    >
      <Box bg={student ? 'purple.500' : 'black'} position="relative" h="100%">
        {student && (
          <Flex
            w="100%"
            flexDirection="column"
            justifyContent="space-between"
            zIndex="10"
            opacity={participant ? '1.0' : '0.5'}
            position="absolute"
            bg={showStarWarning ? 'rgba(255,255,0,0.70)' : 'none'}
            back
          >
            <Flex w="100%" flexDir="row" justifyContent="space-between" p={3}>
              <Flex>
                <Button
                  isDisabled={!participant || starEventTriggered}
                  px={1}
                  py={1}
                  mr={1}
                  bg="green.300"
                  borderRadius="2px"
                  _hover={{ bg: 'green.200' }}
                  onClick={triggerStarEvent}
                >
                  {starEventTriggered ? (
                    '👍'
                  ) : (
                    <Flex w="100%" justifyContent="center" alignItems="center">
                      <Text position="absolute" fontSize="lg" fontWeight="black" p={1}>
                        {participantStarCount || 0}
                      </Text>
                      <Text fontSize="4xl">⭐️</Text>
                    </Flex>
                  )}
                </Button>
                <Text
                  alignSelf="center"
                  px={2}
                  py={2}
                  bg="rgba(0, 0, 0, 0.3)"
                  borderRadius="2px"
                  color="white"
                  fontWeight="bold"
                >
                  {student.name}
                </Text>
                <Text
                  alignSelf="center"
                  px={2}
                  py={2}
                  ml={1}
                  bg="rgba(0, 0, 0, 0.3)"
                  borderRadius="2px"
                  color="white"
                  fontWeight="bold"
                >
                  {student.age}
                </Text>
              </Flex>
              {renderHideVideoPopover()}
            </Flex>
            {renderStarWarning()}
            {student.pronoun && (
              <Flex px={3} pb={3} pt="6.5em">
                <Text
                  px={2}
                  py={2}
                  bg="rgba(0, 0, 0, 0.3)"
                  borderRadius="2px"
                  color="white"
                  fontWeight="bold"
                >
                  {student.pronoun}
                </Text>
              </Flex>
            )}
          </Flex>
        )}

        <Box d="flex" justifyContent="center" position="relative">
          {participant ? (
            <video
              style={{ width: '100%', height: student ? '220px' : 'auto' }}
              ref={videoRef}
              autoPlay
            />
          ) : (
            <Box w="auto" h="220px" />
          )}
          {student && (currentGame || currentQuestion) && (
            <Flex position="absolute" bottom="0" w="100%" zIndex="10">
              <Text
                w="100%"
                px={2}
                py={2}
                bg="rgba(0, 0, 0, 0.7)"
                borderRadius="2px"
                color="white"
                fontWeight="bold"
                textAlign="center"
              >
                {studentResponse || '⏰ Waiting For Response'}
              </Text>
            </Flex>
          )}
        </Box>
        <audio ref={audioRef} autoPlay muted={false} />
      </Box>
      {student && (
        <Box
          d="flex"
          p={2}
          m={0}
          bg={isParticipantSpeaking.isSpeaking && volume !== 0 ? SPEAKING_COLOR : 'purple.400'}
          alignItems="center"
        >
          <Text fontSize="xl">
            <span role="img" aria-label="speaker">
              🔈
            </span>
          </Text>
          <Button
            bg={volume === 0 ? 'red.500' : 'gray.100'}
            color={volume === 0 ? 'white' : 'black'}
            borderWidth={volume === 0 ? '2px' : '1px'}
            borderColor={volume === 0 ? 'green.300' : 'gray.600'}
            _hover={{ bg: 'red.500' }}
            _focus={{ outline: 'none' }}
            ml={2}
            flexGrow="1"
            fontWeight="medium"
            onClick={() => onVolumeChange(student.id, 0)}
          >
            {volume === 0 ? 'Muted' : 'Mute'}
          </Button>
          <Button
            bg={volume === 1 ? 'yellow.300' : 'gray.100'}
            color={volume === 1 ? 'black' : 'black'}
            borderWidth={volume === 1 ? '2px' : '1px'}
            borderColor={volume === 1 ? 'green.300' : 'gray.600'}
            _hover={{ bg: 'yellow.300' }}
            _focus={{ outline: 'none' }}
            ml={2}
            flexGrow="1"
            fontWeight="medium"
            onClick={() => onVolumeChange(student.id, 1)}
          >
            Low
          </Button>
          <Button
            bg={volume === 8 ? 'green.500' : 'gray.100'}
            color={volume === 8 ? 'white' : 'black'}
            borderWidth={volume === 8 ? '2px' : '1px'}
            borderColor={volume === 8 ? 'green.300' : 'gray.600'}
            _hover={{ bg: 'green.500' }}
            _focus={{ outline: 'none' }}
            ml={2}
            flexGrow="1"
            fontWeight="medium"
            onClick={() => onVolumeChange(student.id, 8)}
            style={{
              animation: volume === 8 ? 'colorPulse 1.25s infinite' : '',
            }}
          >
            High
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default Participant;
